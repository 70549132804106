<template>
  <div>
    <topbar
      :titulo="$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULO')"
      :subtitulo="$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULO_DESCRICAO')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <div class="d-flex justify-content-between ml-md-4 gap-1">
          <div></div>
          <b-button variant="secondary" @click="voltarListagemContasPagar">
            {{ $t('GERAL.VOLTAR') }}
          </b-button>
        </div>
      </template>
    </topbar>
    <div class="row">
      <div class="col-12">
        <selecionar-conta
          ref="selecionaConta"
          @selecionado="obterSelecionado"
        />
      </div>
      <!-- <input-select
        class="col-12"
        ref="form.cursoId"
        :options="opcoes.contas"
        v-model="form.contaId"
        :label="$t('FINANCEIRO.SELECIONAR_CONTA')"
        required
        valueField="id"
        textField="descricaoConta"
      /> -->
      <div class="col-12 col-md-4">
        <input-date-only-btn
          ref="dataPagamento"
          v-model="form.dataPagamento"
          label="FINANCEIRO.DATA_PAGAMENTO"
          required
        />
      </div>
      <input-text
        class="col-12 col-md-4"
        label="Data Lançamento:"
        v-model="form.dataLancamento"
        disabled
      >
      </input-text>
      <input-text
        class="col-12 col-md-4"
        label="Data Vencimento:"
        v-model="form.dataVencimento"
        disabled
      >
      </input-text>

      <div class="col-12 col-md-3">
        <input-number
          ref="valor"
          v-model="form.valor"
          :min="0"
          label="FINANCEIRO.VALOR"
          decimal
          disabled
        />
      </div>
      <div class="col-12 col-md-3">
        <input-number
          ref="juros"
          v-model="form.juros"
          :min="0"
          label="Juros (R$)"
          decimal
          @change="editaJurosAtualizaValorTotal(form, $event)"
        />
      </div>
      <div class="col-12 col-md-3">
        <input-number
          ref="desconto"
          v-model="form.desconto"
          :min="0"
          label="Desconto (R$)"
          decimal
          @change="editaDescontoAtualizaValorTotal(form, $event)"
        />
      </div>
      <div class="col-12 col-md-3">
        <input-number
          ref="total"
          v-model="form.total"
          :min="0"
          label="Total"
          decimal
          disabled
        />
      </div>
    </div>
    <div class="d-flex w-100 mt-4" style="justify-content: flex-end">
      <b-button variant="primary" @click="validarFormulario">
        {{ $t('GERAL.SALVAR') }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import {
  InputNumber,
  InputDateOnlyBtn,
  InputText,
  //   InputSelect,
} from '@/components/inputs';
import ContaService from '@/common/services/conta/conta.service';
import TituloService from '@/common/services/titulo/titulo.service';
import SelecionarConta from '@/components/selecionar-entidades/modal/contas/Index';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  props: ['id'],
  components: {
    InputNumber,
    InputDateOnlyBtn,
    InputText,
    // InputSelect,
    SelecionarConta,
    Topbar,
  },
  data() {
    return {
      descricaoContaId: '',
      breadcrumbs: [
        { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
        { titulo: this.$t('FINANCEIRO.LANCAMENTOS') },
        { titulo: this.$t('FINANCEIRO_CONTAS_PAGAR.PAGE_TITLE') },
        { titulo: this.$t('FINANCEIRO_CONTAS_RECEBER.BAIXAR_TITULO') },
      ],
      form: {},
      opcoes: {
        contas: [],
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 100,
        total: 0,
      },
    };
  },
  computed: {
    titulo() {
      return 'Baixar contas a pagar';
    },
    // dataLancamento() {
    //   return helpers.formatarDataBr(this.form.dataLancamento);
    // },
    // dataVencimento() {
    //   return helpers.formatarDataBr(this.form.dataVencimento);
    // },
  },
  mounted() {
    this.getContas();
  },
  methods: {
    voltarListagemContasPagar() {
      this.$router.push({ name: 'financeiro-contas-pagar' });
    },
    validarFormulario() {
      var formValido = true;
      this.$refs['dataPagamento'].$el.style.borderBottom = '';

      var totalParcial =
        parseFloat(this.form.valor) + parseFloat(this.form.juros);
      if (this.form.desconto > totalParcial) {
        formValido = false;
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('Existem Descontos maiores que os valores de documentos')
        );
      }

      if (this.form.dataPagamento == null) {
        formValido = false;
        this.$refs['dataPagamento'].$el.style.borderBottom = '1px solid red';
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('Insira uma data de pagamento')
        );
      }

      if (formValido) {
        this.salvar();
      }
    },
    salvar() {
      this.$store.dispatch(START_LOADING);
      this.form.tipoFormaPagamento = 0;
      TituloService.baixarContaPagar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$router.push({ name: 'financeiro-contas-pagar' });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    obterSelecionado(tipo, item) {
      switch (tipo) {
        case 'conta':
          this.form.contaId = item.id;
          this.form.banco = item.banco;
          this.form.numeroConta = item.numeroConta;
          break;
        default:
          break;
      }
    },
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodas(this.paginacao)
        .then(({ data }) => {
          this.opcoes.contas = data.itens;

          this.opcoes.contas.forEach((item) => {
            item.descricaoConta = `${item.bancoDescricao} | ag: ${item.agencia} | nº:${item.numeroConta} | ${item.tipoContaDescricao} | Fechamento: ${item.periodoFechamento} | Descrição: ${item.descricao} `;
          });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.getDadosTitulo();
        });
    },
    getDadosTitulo() {
      this.$store.dispatch(START_LOADING);
      TituloService.gerarContasPagarPorId(this.$route.params.id)
        .then(({ data }) => {
          this.form = data;
          this.form.dataLancamento = helpers.formatarDataBr(
            this.form.dataLancamento
          );
          this.form.dataVencimento = helpers.formatarDataBr(
            this.form.dataVencimento
          );

          this.opcoes.contas.forEach((item) => {
            if (this.form.contaId == item.id) {
              this.descricaoContaId = `${item.bancoDescricao} | ag: ${item.agencia} | nº: ${item.numeroConta}`;
            }
          });

          let dataAtual = helpers.formatarDataEn(new Date());
          dataAtual = helpers.adicionarTempoEmData(dataAtual);
          this.form.dataPagamento = dataAtual;

          this.$refs['selecionaConta'].iniciaSelecionado(this.descricaoContaId);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editaDescontoAtualizaValorTotal(item, valor_input) {
      this.$refs['desconto'].$el.style.borderBottom = '';
      item.desconto = parseFloat(valor_input);
      if (valor_input == '') {
        item.desconto = 0;
      }
      item.total =
        parseFloat(item.valor) +
        parseFloat(item.juros) -
        parseFloat(item.desconto);

      var totalParcial = parseFloat(item.valor) + parseFloat(item.juros);
      if (valor_input > totalParcial) {
        this.$refs['desconto'].$el.style.borderBottom = '1px solid red';
        return mensagem.warning(
          this.$t('Aviso'),
          this.$t(
            "O valor preenchido em 'Desconto' é maior que a soma entre Valor do documento e Juros"
          )
        );
      }
    },
    editaJurosAtualizaValorTotal(item, valor_input) {
      this.$refs['desconto'].$el.style.borderBottom = '';
      item.juros = parseFloat(valor_input);
      if (valor_input == '') {
        item.juros = 0;
      }

      item.total =
        parseFloat(item.valor) +
        parseFloat(item.juros) -
        parseFloat(item.desconto);
      var totalParcial = parseFloat(item.valor) + parseFloat(item.juros);
      if (item.desconto > totalParcial) {
        this.$refs['desconto'].$el.style.borderBottom = '1px solid red';

        return mensagem.erro(
          this.$t('Aviso'),
          this.$t(
            "O valor de 'Desconto' é maior que a soma entre Valor do documento e Juros"
          )
        );
      }
    },
  },
};
</script>
