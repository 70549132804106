import ApiService from '@/common/api/api.service'

const urlBaseContaPagar = 'Titulo/contas-pagar'

const TituloService = {
    // CONTA PAGAR:
    async criarContaPagar(dados) {
        let result = await ApiService.post(`${urlBaseContaPagar}/criar`, dados)
        return result
    },
    async estornarContaPagar(itemId) {
        let { data } = await ApiService.patch(`${urlBaseContaPagar}/estornar/${itemId}`)
        return data.data
    },
    async baixarContaPagar(item) {
        let { data } = await ApiService.put(`${urlBaseContaPagar}/baixar`, item)
        return data.data
    },
    async cancelarContaPagar(item) {
        let { data } = await ApiService.patch(`${urlBaseContaPagar}/cancelar-titulo`,
            [
                {
                    id: item,
                    cancelamentoObservacao: '',
                },
            ]
        )
        return data
    },
    async ajustarDadosContaPagar(item) {
        let { data } = await ApiService.patch(`${urlBaseContaPagar}/ajustar-dados`, {
            id: item.id,
            dataVencimento: item.dataVencimento
        })
        return data.data
    },
    async filtrarContaPagar(filtro, paginacao) {
        let { data } = await ApiService.get(`${urlBaseContaPagar}/obter-por-filtro`, {
            params: {
                DataLancamentoInicio: filtro.dataLancamentoInicio,
                DataVencimentoInicio: filtro.dataVencimentoInicio,
                DataPagamentoInicio: filtro.dataPagamentoInicio,
                DataLancamentoAte: filtro.dataLancamentoAte,
                DataPagamentoAte: filtro.dataPagamentoAte,
                DataVencimentoAte: filtro.dataVencimentoAte,

                FornecedorId: filtro.fornecedorId,
                HistoricoLancamentoId: filtro.historicoLancamentoId,
                Codigo: filtro.codigo,
                ContaId: filtro.contaId,
                StatusPagamentoId: filtro.statusPagamentoId,

                TamanhoDaPagina: paginacao.tamanhoDaPagina,
                NumeroDaPagina: paginacao.numeroDaPagina
            }
        })
        data.data.itens = data.data.itens.map((row) => ({
            ...row,
            text: row.nome,
            value: row.id,
            _rowVariant: this.corLinhaContasReceber(row),
        }));
        return data.data
    },
    corLinhaContasReceber(row) {
        if (row.statusPagamentoDescricao == 'Liquidado') return 'success';
        if (row.statusPagamentoDescricao == 'Cancelado') return 'danger';
    },
    async gerarRelatorioAnaliseRetroativa(filtro) {
        let { data } = await ApiService.get(`${urlBaseContaPagar}/imprimir-analise-retroativa`, { params: filtro });
        return data;
    },
    // RELATORIOS
    async gerarRelatorioExtrato(filtro) {
        let { data } = await ApiService.get(`${urlBaseContaPagar}/imprimir-extrato`, { params: filtro });
        return data;
    },
    async gerarRelatorioContasPrevistas(filtro) {
        let { data } = await ApiService.get(`${urlBaseContaPagar}/imprimir-relatorio-contas-previstas`, { params: filtro });
        return data;
    },
    async gerarContasPagarPorId(id) {
        let { data } = await ApiService.get(`${urlBaseContaPagar}/busca-por-id?id=${id}`);
        return data;
    },
}

export default TituloService
